.register {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.23); /* sophisticated shadow */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px; /* rounded corners */
    z-index: 100;
    overflow: hidden;
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(159, 156, 152, 0.5);

    }
  .register__container {
    border: 1.5px solid black;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #fff; /* white background */
    padding: 30px;
    padding-top: 0px;
    border-radius: 10px; /* rounded corners */
  }
  .register__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 5px; /* rounded corners */
    border: 1px solid #ccc; /* border color */
  }
  .register div {
    margin-top: 7px;
  }
  .register__close {
    right: 10px;
    top: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    height: max-content;
    text-align: end;
  }