.dashboard {
  text-align: center;
}

/*Serious Stuff */
.jobcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;  /* Ensure full width */
}

.SearchRow {
  width: 120%;
}

.FilterRow {
  justify-content: center;
}

.JobCardsRow {
  justify-content: center;
}


.home-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  gap: 2px;
  top: 20px;
  left: 20px;
  z-index: 1000;
  font-size: 24px;
}

.sidebar {
  position: fixed;
  left: -250px; /* Hide sidebar off-screen */
  top: 0;
  width: 5%;
  height: 100%;
  background-color: #333;
  transition: left 0.3s ease;
  z-index: 500; /* Below the home icon */
  padding-top: 60px; /* Space for top content */
}

.sidebar.open {
  left: 0; /* Slide in */
}

.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  background-color: #575757;
}

.menu {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: x-large;
  text-align: left;
}


.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.apply-button:hover {
  background-color: #0056b3;
}

.application-type-select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 320px; 
  margin-bottom: 10px;
}

