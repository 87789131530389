.menu {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}

.menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.form-container {
  display: grid;
  width: 50vw;
  height: 100vh;

    
}


.text_container {
    text-align: center; /* This centers the text within the container */
    /* If the container itself needs to be centered within its parent, you might need additional styles */
}

.section .info-block, .section .document-block, .section .identity-block {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.personal-info {
    max-width: 600px;
    margin: auto;
}


label {
    margin-right: 10px;
}






